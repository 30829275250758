export const QueryKey = {
	users: 'users',
	content: 'content',
	partners: 'partners',
	shippingMethods: 'shipping-methods',
	userVouchers: 'user-vouchers',
	locations: 'locations',
	startingPoints: 'starting-points',
	productCategories: 'product-categories',
	products: 'products',
	cart: 'cart',
	cartItems: 'cart-items',
	order: 'order',
	discountCodes: 'discount-codes',
	voucherCategories: 'voucher-categories',
	vouchers: 'vouchers',
	payment: 'payment',
	balloons: 'balloons',
	flights: 'flights',
	flightPassengers: 'flight-passengers',
	flightNotifications: 'flight-notifications',
} as const;

export type QueryKey = (typeof QueryKey)[keyof typeof QueryKey];
