import React, { ChangeEventHandler, useEffect } from 'react';
import {
	Flex,
	TextInput,
	Box,
	Chip,
	Slider,
	Select,
	Tooltip,
	UnstyledButton,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { DateInput } from '@mantine/dates';
import { UserVoucherPassenger } from '@/api/UserVoucherPassengerApi';
import { Gender, UUID } from '@/types/types';
import { formatPostalCode, getMaxAvailableDate } from '@/utils/utilities';
import { isEmail, useForm } from '@mantine/form';
import HelpIcon from '@/assets/icons/help-circle.svg?react';
import APP_CONFIG from '@/configs/appConfig';

const voivodeships = [
	'Dolnośląskie',
	'Kujawsko-Pomorskie',
	'Lubelskie',
	'Lubuskie',
	'Łódzkie',
	'Małopolskie',
	'Mazowieckie',
	'Opolskie',
	'Podkarpackie',
	'Podlaskie',
	'Pomorskie',
	'Świętokrzyskie',
	'Wielkopolskie',
	'Zachodniopomorskie',
	'Województwo Śląskie',
	'Warmińsko-Mazurskie',
];

export type PassengerForm = {
	id: UUID;
	firstName: string;
	lastName: string;
	gender: Gender;
	weight: number;
	birthDate?: Date;
	city: string;
	postalCode: string;
	voivodeship: string;
	email: string;
	phoneNumber: string;
	listOrder: number;
};

interface Props {
	passenger: UserVoucherPassenger;
	handleChange: (formPassenger: PassengerForm) => void;
	forceSync?: true;
}

export const EditPassengerForm: React.FC<Props> = ({
	passenger,
	handleChange,
	forceSync,
}) => {
	const form = useForm<PassengerForm>({
		name: `passenger-form-${passenger.id}`,
		initialValues: setFormFromPassenger(passenger),
		validateInputOnBlur: true,
		clearInputErrorOnChange: true,
		validate: {
			email: isEmail('Podaj prawidłowy adres e-mail'),
		},
	});

	const [debounced] = useDebouncedValue(form.values, 250);

	useEffect(() => {
		handleChange(debounced);
	}, [debounced]);

	useEffect(() => {
		if (!forceSync) return;
		form.setValues(setFormFromPassenger(passenger));
	}, [passenger]);

	const handlePostalCodeChange: ChangeEventHandler<HTMLInputElement> = ({
		target,
	}) => {
		form.setFieldValue('postalCode', formatPostalCode(target.value));
	};

	return (
		<Box>
			<Flex
				gap="lg"
				mb="lg"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				<TextInput
					required
					label="Imie"
					placeholder="Imię"
					size="lg"
					className="input-col"
					{...form.getInputProps('firstName')}
				/>
				<TextInput
					required
					label="Nazwisko"
					placeholder="Nazwisko"
					size="lg"
					className="input-col"
					{...form.getInputProps('lastName')}
				/>
			</Flex>
			<Flex
				gap="lg"
				mb="lg"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				<Box className="input-col">
					<label className="label">Płeć</label>
					<Chip.Group
						multiple={false}
						value={form.values.gender.toString()}
						onChange={(val) => form.setFieldValue('gender', Number(val))}
					>
						<Flex gap={12}>
							<Chip value={Gender.FEMALE.toString()}>Kobieta</Chip>
							<Chip value={Gender.MALE.toString()}>Mężczyzna</Chip>
							<Chip value={Gender.OTHER.toString()}>Inna</Chip>
						</Flex>
					</Chip.Group>
				</Box>
				<Box className="input-col" mb="md">
					<label className="label">
						Waga{' '}
						<Tooltip
							w={350}
							label="Upewnij się, czy podana waga jest aktualna. Firma zastrzega sobie prawo do jej weryfikacji na miejscu realizacji przed lotem. W przypadku przekroczenia deklarowanej wagi, doliczana jest dopłata w wysokości 100% wartości vouchera lub celem zapewnienia bezpieczeństwa lotu, pilot może odmówić udziału w locie, bez możliwości ponownej realizacji vouchera."
						>
							<UnstyledButton h={16}>
								<HelpIcon
									className="svg-gray-modern-400"
									width={16}
									height={16}
								/>
							</UnstyledButton>
						</Tooltip>
					</label>
					<Slider
						min={APP_CONFIG.PASSANGER_MIN_WEIGHT}
						max={APP_CONFIG.PASSANGER_MAX_WEIGHT}
						label={(value) => `${value} kg`}
						marks={[
							{ value: 45, label: '45 kg' },
							{ value: 75, label: '75 kg' },
							{ value: 105, label: '105 kg' },
							{ value: 135, label: '135 kg' },
						]}
						{...form.getInputProps('weight')}
					/>
				</Box>
			</Flex>
			<Flex
				gap="lg"
				mb="lg"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				<Box className="input-col">
					<DateInput
						label="Data urodzenia"
						maxDate={getMaxAvailableDate()}
						defaultDate={new Date('1985-01-01')}
						{...form.getInputProps('birthDate')}
					/>
				</Box>
			</Flex>
			<Flex
				gap="lg"
				mb="lg"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				<TextInput
					required
					label="Miejscowość zamieszkania"
					placeholder="Miejscowość"
					size="lg"
					className="input-col"
					{...form.getInputProps('city')}
				/>
				<TextInput
					required
					label="Kod pocztowy"
					placeholder="-- ---"
					size="lg"
					className="input-col"
					value={form.values.postalCode}
					onChange={handlePostalCodeChange}
				/>
			</Flex>
			<Flex
				gap="lg"
				mb="lg"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				<Select
					maw={400}
					w="100%"
					label="Województwo"
					placeholder="Wybierz województwo"
					data={voivodeships}
					{...form.getInputProps('voivodeship')}
				/>
			</Flex>
			<Flex
				gap="lg"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				<TextInput
					required
					label="Adres e-mail"
					placeholder="Adres e-mail"
					type="email"
					size="lg"
					className="input-col"
					{...form.getInputProps('email')}
				/>
				<TextInput
					required
					size="lg"
					label="Numer telefonu"
					placeholder="--- ---- ----"
					type="tel"
					leftSectionWidth={60}
					className="input-col"
					{...form.getInputProps('phoneNumber')}
				/>
			</Flex>
		</Box>
	);
};

function setFormFromPassenger(passenger: UserVoucherPassenger) {
	return {
		...passenger,
		birthDate: passenger.birthDate ? new Date(passenger.birthDate) : undefined,
	};
}
