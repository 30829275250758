import React, {
	useContext,
	ReactNode,
	useEffect,
	useState,
	RefAttributes,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@mantine/hooks';
import { ApiErrorMessage, ForeignLanguage, Language } from '@/types/types';
import { Loading } from '@/components/partials/loading/Loading';
import { isEmpty } from 'lodash';
import { Link, LinkProps } from 'react-router-dom';
import { getFileSrc, isAppError } from '@/utils/utilities';
import parse, { DOMNode, Element, domToReact } from 'html-react-parser';
import ContentApi, { Content, ContentType } from '@/api/ContentApi';
import StorageKey from '@/configs/storageKey';
import LANGUAGES from '@/configs/languages';
import httpClient from '@/api/httpClient';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';
import 'moment/dist/locale/pl';
import { Anchor, AnchorProps } from '@mantine/core';

export enum ErrorCode {
	GENERIC = 'genericError',
	USER_NOT_FOUND = 'userNotFound',
	USER_NOT_ACTIVE = 'userNotActive',
	USER_ALREADY_EXISTS = 'userAlreadyExists',
	DUPLICATED_EMAIL = 'duplicatedEmail',
	MISSING_FIELD = 'missingField',
	INVALID_EMAIL = 'emailIsNotValid',
	INVALID_PASSWORD = 'invalidPassword',
	INVALID_PHONE_NUMBER = 'phoneNumberIsNotValid',
	PASSWORD_TOO_WEAK = 'passwordTooWeak',
	DIFFERENT_PASSWORDS = 'differentPasswords',
	DIFFERENT_EMAILS = 'differentEmails',
	INVALID_REFRESH_TOKEN = 'invalidRefreshToken',
	REFRESH_TOKEN_EXPIRED = 'refreshTokenExpired',
	REFRESH_TOKEN_MALFORMED = 'refreshTokenMalformed',
	INVALID_TOKEN = 'invalidToken',
	INVALID_HASH = 'invalidHash',
	INVALID_POSTAL_CODE = 'invalidPostalCode',
	TOO_MANY_REQUESTS = 'tooManyRequests',
}

const errorSlug = 'error.';

export type AppContent = Record<string, Content>;

interface Context {
	getContent: (
		slug: string,
		options?: {
			links?: Record<string, LinkProps & AnchorProps>;
			vars?: Record<string, string | number>;
		}
	) => string;
	language: Language;
	setLanguage: (lang: Language) => void;
	getErrorMessage: (codes: ApiErrorMessage) => string;
	getMutationErrorMessage: (error: unknown) => string;
	getEntityText: <
		TObj extends {
			translations: Record<
				ForeignLanguage,
				Partial<Record<keyof TObj, string>>
			> | null;
		}
	>(
		obj: TObj,
		key: keyof TObj
	) => string;
}

type GetElementOptions = {
	links?: Record<string, LinkProps & AnchorProps>;
	vars?: Record<string, string | number>;
};

const ContentContext = React.createContext<Context>(null!);

export const useContent = () => useContext(ContentContext);

export const ContentProvider = ({ children }: { children: ReactNode }) => {
	const [content, setContent] = useLocalStorage<AppContent>({
		key: StorageKey.CONTENT,
		defaultValue: {},
		getInitialValueInEffect: false,
	});

	const contentQuery = useQuery({
		queryKey: [ContentApi.queryKey],
		queryFn: ContentApi.getAll,
		staleTime: 1000 * 60 * 5,
		select: (content) =>
			content.reduce<AppContent>((acc, curr) => {
				acc[curr.slug] = curr;
				return acc;
			}, {}),
	});

	useEffect(() => {
		if (!contentQuery.data) return;

		setContent(contentQuery.data);
	}, [contentQuery.data]);

	const [language, setLanguage] = useLocalStorage<Language>({
		key: 'language',
		defaultValue: APP_CONFIG.DEFAULT_LANGUAGE,
		getInitialValueInEffect: false,
	});

	const [_, setRefresher] = useState(false);
	const refreshApp = () => setRefresher((prev) => !prev);

	useEffect(() => {
		const code = LANGUAGES[language].code;

		moment.locale(code);
		document.documentElement.setAttribute('lang', code);
		httpClient.defaults.headers['Accept-Language'] = language.toLowerCase();

		refreshApp();
	}, [language]);

	const isDefaultLanguage = language === APP_CONFIG.DEFAULT_LANGUAGE;

	const checkIfExist = (slug: string) => {
		if (!content[slug]) return false;

		if (isDefaultLanguage)
			return content[slug].type === ContentType.IMAGE
				? !!content[slug].image
				: !!content[slug].content;

		if (!content[slug].translations || !content[slug].translations[language])
			return false;

		if (content[slug].type === ContentType.IMAGE)
			return !!content[slug].translations[language]?.image;

		return !!content[slug].translations[language]?.content;
	};

	const getEntityText: Context['getEntityText'] = (obj, key) => {
		if (isDefaultLanguage) return obj[key] as string;

		return !!obj.translations &&
			!!obj.translations[language] &&
			!!obj.translations[language][key]
			? (obj.translations[language][key] as string)
			: (obj[key] as string);
	};

	const getText = (
		slug: string,
		vars: Record<string, string | number> = {}
	) => {
		let text = isDefaultLanguage
			? content[slug].content
			: content[slug].translations[language]?.content;
		text ??= '';

		for (const [variable, value] of Object.entries(vars)) {
			text = text.replaceAll(variable, value.toString());
		}

		return text;
	};

	const getElement = (slug: string, options?: GetElementOptions) => {
		const raw = getText(slug, options?.vars);

		return parse(raw, {
			replace: (domNode) => {
				const node = domNode as Element;

				if (
					node.name === 'a' &&
					options?.links &&
					options?.links[node.attribs.href]
				) {
					return (
						<Anchor component={Link} {...options.links[node.attribs.href]}>
							{domToReact(node.children as DOMNode[])}
						</Anchor>
					);
				}
			},
		});
	};

	const getImage = (slug: string) => {
		return getFileSrc(
			isDefaultLanguage
				? content[slug].image?.path
				: content[slug].translations[language]?.image?.path
		);
	};

	const getContent: Context['getContent'] = (slug, options) => {
		if (!checkIfExist(slug)) return slug;

		switch (content[slug].type) {
			case ContentType.TEXT:
				return getText(slug, options?.vars);
			case ContentType.ELEMENT:
				return getElement(slug, options) as string;
			case ContentType.IMAGE:
				return getImage(slug);
			default:
				return slug;
		}
	};

	const codeToMessage = (code: ErrorCode | string) =>
		Object.values(ErrorCode).includes(code as ErrorCode)
			? getText(`${errorSlug}${code}`)
			: '';

	const getErrorMessage: Context['getErrorMessage'] = (codes) => {
		const errorMessages = Array.isArray(codes)
			? codes.map((code) => codeToMessage(code))
			: [codeToMessage(codes)];

		const message =
			errorMessages.filter((m) => !!m).join('. ') ||
			codeToMessage(ErrorCode.GENERIC);

		return message;
	};

	const getMutationErrorMessage: Context['getMutationErrorMessage'] = (
		error
	) => {
		if (isAppError(error)) getErrorMessage(error.stack);
		return getErrorMessage(ErrorCode.GENERIC);
	};

	return (
		<ContentContext.Provider
			value={{
				getContent,
				language,
				setLanguage,
				getErrorMessage,
				getMutationErrorMessage,
				getEntityText,
			}}
		>
			{isEmpty(content) ? <Loading /> : children}
		</ContentContext.Provider>
	);
};
