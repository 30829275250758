const LANGUAGES = {
	PL: {
		name: 'Polski',
		flag: 'pl',
		code: 'pl',
	},
	GB: {
		name: 'English',
		flag: 'gb',
		code: 'en',
	},
} as const;

export default LANGUAGES;
