import { Attachment, Translations, UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import EntityApi from './EntityApi';

export enum ContentType {
	TEXT,
	IMAGE,
	ELEMENT,
}

type ContentImage = Pick<Attachment, 'id' | 'path'>;

export interface Content {
	id: UUID;
	slug: string;
	type: ContentType;
	content: string;
	image?: ContentImage;
	translations: Translations<{
		content: string;
		image?: ContentImage;
	}>;
}

class ContentApi extends EntityApi<Content> {
	constructor() {
		super(QueryKey.content);
	}
}

export default new ContentApi();
